import i18n from '@/i18n'
const t = i18n.global.t

export default {
  banner: [
    {
      id: 1004,
      img_url: {
        pc: 'https://backrobo-pic.oss-cn-shanghai.aliyuncs.com/bk-website/index/gn02-pillow.png',
        h5: 'https://backrobo-pic.oss-cn-shanghai.aliyuncs.com/bk-website/index/gn02-pillow.png',
      },
      link: 'https://m.tb.cn/h.gbrihaBCnhnRoAZ?tk=uYXwWIrFuXjHU9046',
      txt: {
        type: 'home.banner.txt04.type',
        text01: 'home.banner.txt04.text01',
        text02: 'home.banner.txt04.text02',
        btn_link: 'home.banner.txt04.btn_link',
      },
      text_show: false
    },
    {
      id: 1001,
      img_url: {
        pc: 'https://nitetronic.oss-us-west-1.aliyuncs.com/aiglobal/home/banner-01.png',
        h5: 'https://nitetronic.oss-us-west-1.aliyuncs.com/aiglobal/home/banner-01.png',
      },
      link: 'javascript: void(0)',
      txt: {
        type: 'home.banner.txt01.type',
        text01: 'home.banner.txt01.text01',
        text02: 'home.banner.txt01.text02',
        btn_link: 'home.banner.txt01.btn_link',
      },
      text_show: true
    },
    {
      id: 1002,
      img_url: {
        pc: 'https://nitetronic.oss-us-west-1.aliyuncs.com/aiglobal/home/banner-02.png',
        h5: 'https://nitetronic.oss-us-west-1.aliyuncs.com/aiglobal/home/banner-02.png',
      },
      link: 'javascript: void(0)',
      txt: {
        type: 'home.banner.txt02.type',
        text01: 'home.banner.txt02.text01',
        text02: 'home.banner.txt02.text02',
        btn_link: 'home.banner.txt02.btn_link',
      },
      text_show: true
    },
    {
      id: 1003,
      img_url: {
        pc: 'https://nitetronic.oss-us-west-1.aliyuncs.com/aiglobal/home/banner-03.png',
        h5: 'https://nitetronic.oss-us-west-1.aliyuncs.com/aiglobal/home/banner-03.png',
      },
      link: 'javascript: void(0)',
      txt: {
        type: 'home.banner.txt03.type',
        text01: 'home.banner.txt03.text01',
        text02: 'home.banner.txt03.text02',
        btn_link: 'home.banner.txt03.btn_link',
      },
      text_show: true
    },
  ],
  videos: {
    link: 'https://nitetronic.oss-us-west-1.aliyuncs.com/aiglobal/home/home-video.mp4',
    img_url: 'https://nitetronic.oss-us-west-1.aliyuncs.com/aiglobal/home/home-video-bg.png',
  },
  news: [
    {
      id: 101,
      img_link: 'https://nitetronic.oss-us-west-1.aliyuncs.com/aiglobal/home/news-01.png',
      bg_link: 'https://nitetronic.oss-us-west-1.aliyuncs.com/aiglobal/home/news-01-bg.png',
      title: 'bknews.news_title1',
      desc: 'bknews.new_subtitle1',
      date: '2023.01.07',
      link: 'https://www.ces.tech/innovation-awards/honorees/2023/honorees/a/airly-chair-(deluxe-version).aspx',
    },
    {
      id: 102,
      img_link: 'https://nitetronic.oss-us-west-1.aliyuncs.com/aiglobal/home/news-02.png',
      bg_link: 'https://nitetronic.oss-us-west-1.aliyuncs.com/aiglobal/home/news-01-bg.png',
      title: 'bknews.news_title5',
      desc: 'bknews.new_subtitle5',
      date: '2023/03/21',
      link: 'https://mp.weixin.qq.com/s/yOdRSZFfw4_tuiPJ0x4D4w',
    },
    {
      id: 103,
      img_link: 'https://backrobo-pic.oss-cn-shanghai.aliyuncs.com/bk-website/index/news-06.png',
      bg_link: 'https://backrobo-pic.oss-cn-shanghai.aliyuncs.com/bk-website/index/news-06-bg.png',
      title: 'bknews.news_title6',
      desc: 'bknews.new_subtitle6',
      date: '2024/06/19',
      link: 'https://mp.weixin.qq.com/s/MjzeANKpjRBmEdGD82YmBg',
    },
  ],
}
