export default {
  home: {
    menu: {
      home: 'home',
      nitetronic: '添眠',
      backrobo: '贝氪',
      concept: '可持续发展',
      support: '服务支持',
      aboutus: '关于我们',
    },
    aboutusMenu: {
      company: '公司简介',
      devolopment: '发展历程',
      cultrue: '企业文化',
      honour: '企业荣誉',
      technology: '创新技术',
      news: '新闻中心',
    },
    footer: {
      brand01: {
        name: '品牌矩阵',
        label01: '添眠',
        label02: '贝氪',
      },
      brand02: {
        name: '添眠店铺',
        label01: '添眠天猫旗舰店',
        label02: '添眠京东旗舰店',
        label03: '添眠抖音旗舰店',
      },
      brand03: {
        name: '贝氪店铺',
        label01: '贝氪天猫旗舰店',
        label02: '贝氪京东旗舰店',
        label03: '贝氪抖音旗舰店',
      },
      brand04: {
        name: '关于我们',
        label01: '公司简介',
        label02: '全球健康云',
        label03: '柔性气囊技术',
        label04: '可持续发展',
      },
      brand05: {
        name: '新闻中心',
        label01: '最新资讯',
      },
      contact: {
        phone: '品牌热线',
        email: '品牌邮箱',
        collection: '关注贝氪',
      },
    },
    banner: {
      txt01: {
        type: 'Z6',
        text01: '一键干预 ',
        text02: '呵护触手可及 ',
        btn_link: ' 添眠智能止鼾枕 >',
      },
      txt02: {
        type: 'C2',
        text01: '会「动」',
        text02: ' 更懂你的痛',
        btn_link: ' 贝氪智能气动好腰椅 >',
      },
      txt03: {
        type: 'T1',
        text01: '给你「自然醒」',
        text02: '的早晨',
        btn_link: ' 添眠智能助眠床垫 >',
      },
      txt04: {
        type: '',
        text01: '',
        text02: '',
        btn_link: '',
      },
      txt06: {
        type: 'G1',
        text01: '卸下疲惫',
        text02: '即刻深睡',
        btn_link: '添眠智能深睡床垫 >',
      },
    },
    video: {
      title: '给你的，智慧理想家',
      sub: 'For your ideal home',
    },
    techlife: {
      en: 'SMART LIFE',
      zh: '科技定制 智慧生活',
      first_title: '刷新你的「健康生活」',
      first_sub: '贝氪全球健康云',
      more: '了解更多',
      sec_title: '一起探索',
      sec_sub: '智慧「气囊」的奥秘',
      more: '了解更多',
    },
    news: {
      en: 'NEWS ROOM',
      title: '科技定制 智慧生活',
    },
  },
  aboutus: {
    block01: '令人赞叹的，动态舒适感',
    block02: '20年钻研,智慧气囊技术™',
    block03: '动态释压',
    block04: '律动按摩',
    block05: '主动撑腰',
    block06: '模拟侧睡',
    block07: '气囊运行 无噪无扰',
    block08: '35分贝以下的运行声，真的很难被听见',
    block09: '每一份专利报告',
    block10: '都是付诸实践应用的证明',
    block11: '为健康，创新不止步',
  },
  backrobo: {
    block01: {
      tag: 'Airly chair',
      txt01: '坐得好',
      txt02: '做得更好',
    },
    block02: {
      title: {
        en: 'BRANDSTORY',
        zh: '品牌故事',
      },
      desc: {
        1: {
          tag: '贝氪',
          label01:
            'Backrobo贝氪专注于8小时智慧办公，致力于用前沿技术和人体工学的研究，通过智能技术、新型材料的应用，为现代办公人群提供改善身体亚健康状态的产品和智慧解决方案。以技术创新为驱动，为大家带去健康舒适、高效智能的办公体验。',
        },
      },
      more: '了解更多',
    },
    block03: {
      title: '贝氪智能气动好腰椅',
      sub_title: '智慧气囊随动托腰｜人体工学设计｜APP多种模式选择',
      production: {
        1: {
          type: 'C1',
          txt01: '携手华为、小米',
          txt02: '硬核赋能智慧生活',
        },
        2: {
          type: 'C2',
          txt01: '多彩升级',
          txt02: '点亮办公空间',
        },
      },
    },
    block04: {
      title: '贝氪多功能零压午休枕',
      sub_title: '疲惫时的柔软依靠',
      production: {
        type: 'M1',
        txt01: '软弹支撑  零压放松',
        txt02: '贝氪零压午休枕',
      },
    },
    block05: {
      txt01: '贝氪Backrobo',
      txt02: '始终专注8小时智慧办公',
    },
  },
  nitetronic: {
    block01: {
      txt01: '科技睡眠',
      txt02: '用添眠',
    },
    block02: {
      title: {
        en: 'BRANDSTORY',
        zh: '品牌故事',
      },
      desc: {
        1: {
          tag: '初心',
          label01: '成立于2019年，专注于8小时智慧睡眠',
          label02: '致力用创新科技为用户增添好睡眠，为爱添眠',
        },
        2: {
          tag: '科技',
          label01: '独创的，非接触式健康传感器',
          label02: '20年动态智慧气囊技术',
        },
        3: {
          tag: '颠覆',
          label01: '止鼾枕品类开创者',
          label02: '收购德国品牌Nitetronic，并不断升级止鼾好眠芯体验',
          label03: '研创Airfloatingm舒眠技术',
          label04: '',
        },
      },
      more: '了解更多',
    },
    block03: {
      title: '添眠·智能止鼾枕系列',
      sub_title: '非侵入式、无佩戴的减鼾方案｜气囊技术推动头部变仰睡为侧睡',
      production: {
        1: {
          type: 'Z6',
          txt01: '按键调节 长辈友好',
          txt02: '',
          txt03: '添眠智能止鼾枕',
        },
        2: {
          type: 'Z1',
          txt01: '物理止鼾 无扰睡眠',
          txt02: '',
          txt03: '添眠智能止鼾枕',
        },
        3: {
          type: 'M6',
          txt01: '数字诊疗  预防OSA',
          txt02: '',
          txt03: '添眠智能止鼾枕',
        },
      },
    },
    block04: {
      title: '添眠·智能舒眠床垫系列',
      sub_title: 'Airfloatingm舒眠技术｜气囊按摩放松身体｜智能温控系统',
      production: {
        type: 'T1',
        txt01: '按摩放松  助眠舒睡',
        txt02: '',
        txt03: '添眠智能助眠床垫',
      },
    },
    block05: {
      txt01: '',
      txt02: '始终专注8小时智慧睡眠',
    },
  },
  technology: {
    block01: {
      tag: '健康数据化云生态',
      title: '贝氪全球健康云',
      sub: '贝氪是以IOT智慧物联网和云平台为基础打造的智能健康生态体系，通过全链路闭环的技术积累，覆盖用户每日约20个小时的“坐、睡、行”的行为健康干预，让产品拥有更主动智能和更硬核的人机交互能力，为用户创造更具有幸福感的健康生活体验。',
    },
    block02: {
      title: '独特的非接触式健康传感器',
      '01': {
        titles: {
          1: '多点矩阵压力传感',
        },
        labels: {
          1: '基于压力和重力角度变化算法的坐姿检测技术，可准确测量使用者的基础坐姿。用于匹配最佳的支撑模式。',
        },
      },
      '02': {
        titles: {
          1: 'MEMS鼾声传感',
        },
        labels: {
          1: '鼾声传感及人体识别传感相结合。',
          2: '精准识别当前使用者发出的鼾声，直接过滤掉其余噪声，为后续识别算法提供有效数字信号。',
        },
      },
      '03': {
        titles: {
          1: '基于流体的',
          2: '高精度体征传感',
        },
        labels: {
          1: '基于PVDF压电薄膜精确抓取心跳波、呼吸波、鼾声振动、OSA典型振动等多参数生物信号。目前已能精确捕捉不同心率不齐、窦性心律等不同信号。',
        },
      },
      '04': {
        titles: {
          1: '非接触式人体检测',
        },
        labels: {
          1: '超薄非接触检测：通过感应式传感器阵列实现非接触识别。',
          2: '精准识别：通过信号特征算法可区分人体部位及其位置，有效排除宠物、非生命等物品干扰。',
        },
      },
    },
    block03: {
      title: '全球化，IOT技术布局',
      label: '可支撑100万设备同时运行',
    },
    block04: {
      title: '专业的，“云”合作伙伴',
    },
  },
  concept: {
    block01: {
      title: '每一步 ，都秉承可持续发展的理念',
    },
    block02: {
      title: '看得见的未来',
      label01: '原材料',
      label02: '拆解回收',
      label03: '生产制造',
      label04: '产品使用',
      label05: '物流运输',
    },
    block03: {
      '01': {
        title: '源头，就要用环保高效的',
        subtitle01: '我们的产品采用高效率的电池以减少能源消耗，',
        subtitle02: '未来也将使用太阳能等可再生能源进行充电',
        subtitle02: '未来也将使用太阳能等可再生能源进行充电',
      },
      '02': {
        title: '小小的，好运输 ',
        subtitle01: '组装式的产品设计，使包装体积大大减小，减少商品包装耗材和',
        subtitle02: '包装垃圾，让商品包装在使用后更易于回收、处理、降解；当然',
        subtitle03: '我们也贴心附赠组装工具，组装轻松省心，同时也为环保出一份',
        subtitle04: '力。',
      },
      '03': {
        title: '智能适配，产品主动适人',
        subtitle01: '通过大数据及人性化产品设计，可适配不同身高体型用户，更好',
        subtitle02: '的做到资源共享。',
      },
      '04': {
        title: '10年+超长使用年限',
        subtitle01: '我们不断测试产品的耐用性和可维护性，不断延长使用寿命，从',
        subtitle02: '而减少产品替换或丢弃的频次，减少废物产生。',
        subtitle03: '*数据仅品牌测试，仅供参考，具体使用因人而异',
      },
      '05': {
        title: '是包装盒，也是孩子的开心乐园',
        subtitle01: '包装盒不丢弃，可以制作成小房子，给小孩和萌宠的开心玩耍空间。',
      },
    },
    block04: {
      title: '可回收，不变的承诺',
      label01: '我们与合作伙伴一起， ',
      label02: '承诺对产品寿命结束后的环保处理， ',
      label03: '提供回收服务或换购服务，一起共同创造一个可持续的未来。',
    },
  },
  bknews: {
    slogan: 'AI智能 引领现代健康生活',
    news: '新闻中心',
    tab1: '全部',
    tab2: '新闻资讯',
    tab3: '公司活动',
    more: '了解更多',
    news_title1: '重磅！贝氪智能气动好腰椅C2荣获CES创新设计奖',
    new_subtitle1:
      'Airly Chair (Deluxe Version) is an intelligent ergonomic office chair. Compared with current ergonomic chairs, Airly Chair is equipped with intelligent somatosensory chip and airly...',
    news_title2: 'AIWORKS GLOBAL 惊艳亮相拉斯维加斯CES展览',
    new_subtitle2:
      '美国当地时间1月5日，2023年国际消费电子展（CES）在拉斯维加斯拉开帷幕，在被新冠疫情打乱两年节奏后，CES终于回归线下。自1967年创办以来，CES已走过56个年头。据悉，本届CES展迎来3143家参展企业，其中中国大陆的参展商从去年的155家增加到480家。Interbrand全球品牌百强中的85家企业，40家顶级零售商均已...',
    news_title3: '品牌应邀出席第五届中国睡眠产业峰会',
    new_subtitle3:
      '3月21日上海贝氪若宝健康科技有限公司（下称“贝氪科技”）成为“2022新浪健康养生睡眠健康总评榜”上榜企业，荣获“2022睡眠产业新锐企业”称号，旗下添眠品牌也受邀出席2023世界睡眠日·中国睡眠产业峰会。在本次第五届睡眠产业峰会现场，贝氪科技董事长韩道虎先生以“智慧睡眠 AI大数据闭环应用”为主题发表演讲，描述了添眠品牌在未来...',
    news_title4: '贝氪科技首次亮相第五届进口博览会',
    new_subtitle4:
      '2022年11月5日，【上海：第五届中国国际进口博览会】在上海盛大开幕，贝氪若宝健康科技有限公司（以下简称“贝氪科技”）在“四叶草”中央广场-6天+365天一站式交易服务平台亮相本届盛会，全方位展示旗下两款代表产品——添眠智能止鼾枕&贝氪智能气动好腰椅。中国国际进口博览会是世界上第一个以进口为主题的国家级展会，共有145个国家、地区和国际组织参...',
    news_title5: '贝氪科技董事长韩道虎对话未来医疗服务平台动脉网',
    new_subtitle5:
      '医疗市场正随可支配收入的增长，以及健康管理意识的提高由疾病驱动向健康驱动转型。转型之下，消费医疗作为融合消费与医疗两大属性的板块，体现出极佳的成长性与发展稳定性。由于其终端覆盖人群广、应用消费场景丰富，未来可围绕消费者健康需求持续提供多元化产品与服务等属性...',
    news_title6: '添眠AI深睡房已经入驻多家酒店品牌，为您开启智慧深睡新体验！',
    new_subtitle6:
      '添眠AI深睡房是面向未来酒旅宾客提供科学健康深睡的一种新的高端房型，房间内搭载数智化软硬件系统和智能设备，对用户进行实时的睡眠监测和睡眠诱导。目前多家酒店陆续上线了<添眠AI深睡房>的高端房型...',
  },
  us: {
    slogan1: '坐得好 做的更好',
    slogan2: '科学减鼾 为爱添眠',
    about_us: '关于我们',
    us_info1:
      'Aiworks global 上海贝氪若宝健康科技有限公司正式成立于2019年，是一家致力于将人工智能（AI）技术应用于健康领域的全球性企业，公司聚焦8小时健康办公和8小时智慧睡眠健康关怀，基于AI健康云和各种硬核产品来完善 “坐、睡、行”以便覆盖个人每日约20个小时的行为健康干预生态。',
    us_info2:
      'Aiworks global旗下拥有两大品牌Nitetronic添眠和Backrobo贝氪，Nitetronic添眠专注智慧睡眠领域，Backrobo贝氪专注智能办公领域。助力用户追求高品质生活的同时，把健康融入每一个人的日常生活。',
    us_know_more: '了解更多',
    history: '发展历程',
    history_2019: {
      info1: '上海贝氪若宝健康科技有限公司成立',
      info2: '苏州贝氪智能科技有限公司成立',
    },
    history_2020: {
      info1: '通过科技型中小型企业认定',
      info2: '获得中标研质量管理体系认证',
      info3: '孵化“Backrobo贝氪”智慧办公品牌',
      info4: '推出贝氪智能托腰办公椅并在小米有品首发众筹',
    },
    history_2021: {
      info1: '收购德国“Nitetronic”止鼾枕品牌',
      info2: '孵化”Nitetronic添眠”智慧睡眠品牌',
      info3: '推出Nitetronic添眠智能止鼾枕系列',
    },
    history_2022: {
      info1: '通过上海高新技术企业认定',
      info2: '以Aiworks Global海外企业命名正式开展全球业务',
      info3: '添眠止鼾枕Z6海外Kickstarter&Indiegogo发起众筹',
      info4: '贝氪科技首次参展2022中国国际进口博览会',
    },
    history_2023: {
      info1: 'Aiworks Global首次亮相2023年国际消费电子展（CES）',
      info2: '贝氪智能托腰办公椅华为商城首发众筹',
      info3: '推出医疗型添眠®M6型智能止鼾枕',
      info4: '推出贝氪多功能零压午休枕',
      info5: '推出添眠零压睡眠眼罩',
    },
    history_future: {
      info1: '上海贝氪若宝健康科技有限公司成立',
      info2: '苏州贝氪智能科技有限公司成立',
    },
    culture: '企业文化',
    culture_title1: '贝氪使命',
    culture_subtitle1_l: '做让用户感到',
    culture_subtitle1_r: '幸福的健康产品',
    culture_title2: '贝氪愿景',
    culture_subtitle2_l: '成为引领智能健康',
    culture_subtitle2_r: '硬科技的全球化企业',
    culture_title3: '贝氪价值观',
    culture_subtitle3_1: '做有价值,对社会有意义的事',
    culture_subtitle3_2: '以客户为中心，为客户创造价值',
    culture_subtitle3_3: '成就公司，成就自己',
    culture_subtitle3_4: '勤勉向上,奋斗不息',
    culture_subtitle3_5: '快乐工作,幸福一生',
    honor: '企业荣誉',
    honor_title1_t: '添眠品牌荣获',
    honor_title1_b: '天猫年度超级新物种奖',
    honor_title2_t: '获新浪健康养生',
    honor_title2_b: '睡眠产业新锐企业奖',
    honor_title3_t: '',
    honor_title3_b: '中国睡眠研究会成员',
  },
  help: {
    help: '服务支持',
    help_title: '欢迎使用Aiworks服务',
    help_subtitle: '我们时刻把您放在心中',
    nitetronic: '添眠',
    backrobo: '贝氪',
    help_install: '安装视频',
    help_intro: '产品说明书',
    help_app: 'APP下载',
    help_problem: '常见问题',
    help_contact: '联系我们',
    help_contact_call: '服务热线',
    help_contact_mail: '服务邮箱',
    help_contact_wechat: '官方微信',
    nitetronic_problem1_question: '添眠智能止鼾枕 Z1和Z1P有什么区别？',
    nitetronic_problem1_answer:
      '两者外观一致，Z1是固定枕高版本，Z1P是高低可调版本，枕头内置2层可拆卸垫层，通过拉开拉链，拿掉枕头内的垫层，来降低枕头的整体高度。',
    nitetronic_problem2_question: '添眠智能止鼾枕Z1、Z6和M6有什么区别?',
    nitetronic_problem2_answer:
      'Z1是固定枕高版本，通过添眠app调节睡眠参数，Z6在Z1款基础上，新增枕高可调节选项，同时也可通过控制盒上的按键来调节睡眠参数。M6款是医用款，在Z6的基础上，app新增OSA筛查等功能。',
    nitetronic_problem3_question: '添眠智能止鼾枕如何配网?',
    nitetronic_problem3_answer_01: '①扫描控制盒正面二维码，下载“添眠”APP，注册并登录账号',
    nitetronic_problem3_answer_02: '②打开电源开关，长按控制盒上wifi键直至wifi灯快闪',
    nitetronic_problem3_answer_03: '③点击app内-我的-添加设备，扫描控制盒背面二维码;',
    nitetronic_problem3_answer_04: '④按照APP指示进行操作',
    nitetronic_problem4_question: '添眠智能止鼾枕如何清洗?',
    nitetronic_problem4_answer:
      '添眠智能止鼾枕的本体不可水洗或干洗。取下枕套后，使枕头置于通风处或用微湿的布轻轻擦拭保护内套表面，去除灰尘或污渍。该内套能保护枕头内部敏感科技元器件。请不要拆除保护内套——若有破损则不在保修范围内。枕头的外套可拆卸并根据水洗标（枕套内侧）上的指示清洗。请翻面清洗并拉上拉链。',
    nitetronic_problem5_question: '添眠智能止鼾枕工作原理?',
    nitetronic_problem5_answer:
      '添眠智能止鼾枕通过使用者的声音频率和声音震动，多因素数据模式识别鼾声，不受其他声音（伴侣鼾声、音乐、聊天）影响。工作原理：客户睡眠打鼾时，传感器感受客户鼾声从而判定打鼾时，头部对应位置气囊充气，轻柔推动头部侧转，增宽呼吸道，直至打鼾停止或鼾声明显减少。',
    nitetronic_problem6_question: '如何在不打鼾时检测枕头是否正常?',
    nitetronic_problem6_answer:
      '可以使用演示模式来观察枕头是否工作，方法：点击添眠app中我的界面-点击演示模式-点击开始演示-保持头部在枕头上即可开启演示模式，演示模式下，气囊会模拟鼾声干预动作，进行气囊轮动。',
    backrobo_problem1_question: 'C1、C1X、C2有什么区别',
    backrobo_problem1_answer_01: 'C1X和C1都是使用米家app，区别是C1X新增背部承托区、可调节扶手、可拆卸电池、W型人体工学坐垫，可选颜色也更加丰富。',
    backrobo_problem1_answer_02: 'C2使用贝氪自研app，在C1X基础上新增腰部热敷及午休模式，并且可在app内随时开启按摩，产品材质方面也有升级。',
    backrobo_problem2_question: '智能办公椅有哪些调节功能?',
    backrobo_problem2_answer:
      '办公椅可以上下调节高度60mm或者70mm（C2款）；背靠可调节后仰角度为25°；头枕可以上下前后调节40mm和40°；还可以调节后仰的力度；通过椅座上的按键来切换手动和自动模式。另外C1X和C2可调节扶手的上下升降60mm，左右旋转12度，前后移动60mm,C2还可调节气囊热敷温度。',
    backrobo_problem3_question: '智能托腰办公椅初次使用步骤?',
    backrobo_problem3_answer: '按照安装视频组装好办公椅，检查电池电量是否有电。出厂后首次开机，用户入座后需要按“SMART”键开机（C1和C1X是短按，C2是长按）',

    backrobo_problem4_question: '智能拖腰办公椅如何配网（以C2为例）?',
    backrobo_problem4_answer_01: '①入座后长按smart键开机',
    backrobo_problem4_answer_02: '②扫描座椅或者说明书二维码，下载“贝氪”app',
    backrobo_problem4_answer_03: '③注册并登录贝氰账号',
    backrobo_problem4_answer_04: '④操作[添加设备]',
    backrobo_problem4_answer_05: '⑤按APP 提示操作，为设备配网',

    backrobo_problem5_question: '办公椅久坐提醒是怎么工作的？?',
    backrobo_problem5_answer: '通过APP设定久坐时间计时。当累积入座时间达到我们设定好的时间，办公椅椅背的气囊会自动充放气，对用户进行2分钟的腰部按摩放松提醒。',
    backrobo_problem6_question: '日常办公椅如何清洁?',
    backrobo_problem6_answer: '中性清洁剂少量喷在干布上，擦拭办公椅污渍区域，晾干或者吹干，不可水洗。',
  },
}
