export default {
  home: {
    menu: {
      home: 'home',
      nitetronic: 'Nitetronic',
      backrobo: 'Backrobo',
      concept: 'Development',
      support: 'Support',
      aboutus: 'About us',
    },
    aboutusMenu: {
      company: 'Company',
      devolopment: 'Development History',
      cultrue: 'Culture',
      honour: 'Honor',
      technology: '创新技术',
      news: 'News Center',
    },
    footer: {
      brand01: {
        name: 'Brand',
        label01: 'Nitetronic',
        label02: 'Backrobo',
      },
      brand02: {
        name: 'Buy',
        label01: 'Smart Anti-Snore Pillow',
        label02: 'Air Smart Office Chair',
        label03: '',
      },
      // brand03: {
      //   name: '贝氪店铺',
      //   label01: '贝氪天猫旗舰店',
      //   label02: '贝氪京东旗舰店',
      //   label03: '贝氪抖音旗舰店',
      // },
      brand04: {
        name: 'About us',
        label01: 'Introduction',
        label02: 'Global Health Cloud',
        label03: 'Technology of Soft and Flexible Air Chambers ',
        label04: 'Sustainable Development',
      },
      brand05: {
        name: 'News Center',
        label01: 'Latest News',
      },
      brand06: {
        name: 'Service and Support',
        label01: 'FAQs',
      },
      contact: {
        phone: 'Phone',
        email: 'Email',
        collection: 'Social',
      },
    },
    banner: {
      txt01: {
        type: 'Z6',
        text01: 'Less Snoring,',
        text02: 'Better Life',
        btn_link: 'Smart Anti-Snore Pillow >',
      },
      txt02: {
        type: 'C2',
        text01: 'Automatic Lumbar Following System',
        text02: 'to support your lumbar',
        btn_link: 'Air Smart Office Chair >',
      },
      txt03: {
        type: 'T1',
        text01: 'Air Massage,',
        text02: 'Relax Your Body',
        btn_link: 'Nitetronic Calming Air Smart Mattress Topper >',
      },
      txt04: {
        type: '',
        text01: '',
        text02: '',
        btn_link: '',
      },
      txt06: {
        type: '',
        text01: '',
        text02: '',
        btn_link: '',
      },
    },
    video: {
      title: 'For your Ideal Home',
      sub: '',
    },
    techlife: {
      en: 'SMART LIFE',
      zh: 'Technological Customization, Smart Life',
      first_title: 'Refresh Your Healthy Life',
      first_sub: 'Backrobo Global Health Cloud',
      more: 'Learn More',
      sec_title: "Let's explore",
      sec_sub: 'the mysteries of the smart airbags',
      more: 'Learn More',
    },
    news: {
      en: 'NEWS ROOM',
      title: 'Technological Customization, Smart Life',
    },
  },
  aboutus: {
    block01: 'Amazing, dynamic, comfortable experience',
    block02: "20 years' research of smart air chambers",
    block03: 'Dynamic pressure relief',
    block04: 'Rhythmic massage',
    block05: 'Fit the lumbar automatically',
    block06: 'Simulate side-head sleeping',
    block07: 'Low noise during the operation of the air chambers.',
    block08: 'The sound is below 35 decibels, which is almost inaudible',
    block09: 'Every patent report',
    block10: 'is a proof of practical application',
    block11: 'For health, we constantly innovate and never stop',
  },
  backrobo: {
    block01: {
      tag: 'Airly chair',
      txt01: 'We got your back',
      txt02: '做得更好',
    },
    block02: {
      title: {
        en: 'BRANDSTORY',
        zh: 'Brand Story',
      },
      desc: {
        1: {
          tag: 'Backrobo',
          label01:
            'Backrobo focuses on 8-hour intelligent office, dedicated to providing products and smart solutions for improving suboptimal physical health conditions for modern office workers  through cutting-edge technology and ergonomic research, using intelligent technology and new materials. Driven by technological innovation, it brings everyone a healthy, comfortable, and efficient intelligent office experience.',
        },
      },
      more: 'Learn More',
    },
    block03: {
      title: 'Backrobo Air Massage Smart Chair',
      sub_title: 'The air chambers adjust automatically to fit your sitting posture｜Ergonomic Design｜Timed Reminder',
      production: {
        1: {
          type: 'C1',
          txt01: '携手华为、小米',
          txt02: '硬核赋能智慧生活',
        },
        2: {
          type: 'C2',
          txt01: 'Classic colors',
          txt02: 'Elegant, Sophisticated',
        },
      },
    },
    block04: {
      title: 'Backrobo Nap Pillow',
      sub_title: 'Support you softly when you are exhausted',
      production: {
        type: 'M1',
        txt01: 'Unique design, 0 pressure concept',
        txt02: 'Backrobo Nap Pillow',
      },
    },
    block05: {
      txt01: 'Backrobo',
      txt02: 'dedicated to 8 hours of smart working',
    },
  },
  nitetronic: {
    block01: {
      txt01: 'Nitetronic for',
      txt02: 'AI Sleep',
    },
    block02: {
      title: {
        en: 'BRANDSTORY',
        zh: 'Brand Story',
      },
      desc: {
        1: {
          tag: 'Mission',
          label01: 'The company was established in 2022. We are dedicated to 8 hours of intelligent sleep',
          label02: 'We are committed to using innovative technology to provide users with better sleep and better life',
        },
        2: {
          tag: 'Technology',
          label01: 'Innovative and non-contact health sensors',
          label02: "20 years' research of dynamic smart airbags",
        },
        3: {
          tag: 'Innovation',
          label01: 'Pioneer in the Anti-Snore Pillow Category',
          label02: 'Acquired German brand, Nitetronic and continuously upgraded the anti-snoring sleep experience',
          label03: 'Developed the Airfloating comfort sleep technology',
          label04: 'Launched the Dynamic Smart Mattress Topper',
        },
      },
      more: 'Learn More',
    },
    block03: {
      title: 'Nitetronic·Smart Anti-Snore Pillow series',
      sub_title: 'Non-invasive, non-wearable｜Clinically proven anti-snore pillow to reduce snoring',
      production: {
        1: {
          type: 'Z6',
          txt01: 'Adjustment Buttons  Newly upgraded',
          txt02: '',
          txt03: 'Nitetronic Smart Anti-Snore Pillow ',
        },
        2: {
          type: 'Z1',
          txt01: 'Physical Solution Undisturbed Sleep',
          txt02: '',
          txt03: 'Nitetronic Smart Anti-Snore Pillow ',
        },
        3: {
          type: 'M6',
          txt01: '数字诊疗  预防OSA',
          txt02: '',
          txt03: '添眠智能止鼾枕',
        },
      },
      more: 'Learn More',
    },
    block04: {
      title: 'Nitetronic·Calming Air Mattress Topper',
      sub_title: 'Airfloating Technology｜Air massage to relax the body｜Smart Temperature Management',
      production: {
        type: 'T1',
        txt01: 'Air Massage to relax the body  Sleep fast and sound',
        txt02: '',
        txt03: 'Nitetronic Calming Air Mattress Topper',
      },
    },
    block05: {
      txt01: 'Nitetronic',
      txt02: 'dedicated to 8 hours of healthy sleep',
    },
    more: 'Learn More',
  },
  technology: {
    block01: {
      tag: 'Health data ecology',
      title: 'Backrobo Global Health Cloud',
      title1: 'Health Cloud',
      sub: 'Through the accumulation of full-link closed-loop technology, it covers about 20 hours of behavioral health intervention of "sitting, sleeping and walking" for users every day, so that the product has more active intelligence and more hardcore human-computer interaction capabilities, and creates a more happy and healthy life experience for users.',
    },
    block02: {
      title: 'Unique contactless health sensor',
      '01': {
        titles: {
          1: 'Multi-point matrix pressure sensing',
        },
        labels: {
          1: "Sitting posture detection technology based on pressure and gravity angle change algorithms can accurately measure the user's basic sitting posture. It is used to match the optimal support pattern.",
        },
      },
      '02': {
        titles: {
          1: 'MEMS snoring sensors',
        },
        labels: {
          1: 'Snoring sensing and human recognition sensing are combined.',
          2: 'Accurately identify the snoring sound emitted by the current user, directly filter out the rest of the noise, and provide effective digital signals for subsequent recognition algorithms. ',
        },
      },
      '03': {
        titles: {
          1: '“Fluid-based',
          2: ' High-precision sign sensing”',
        },
        labels: {
          1: 'Based on PVDF piezoelectric film, it can accurately grasp multi-parameter biological signals such as heartbeat waves, respiratory waves, snoring vibrations, and OSA typical vibrations. At present, it can accurately capture different signals such as arrhythmia and sinus rhythm.',
        },
      },
      '04': {
        titles: {
          1: 'Non-contact human detection',
        },
        labels: {
          1: 'Ultra-thin non-contact detection: Non-contact identification with an array of inductive sensors.',
          2: 'Accurate recognition: Through the signal feature algorithm, the human body parts and their positions can be distinguished, and the interference of pets, non-living items and other items can be effectively excluded.',
        },
      },
    },
    block03: {
      title: 'Globalization, IOT technology layout',
      label: 'It can support the simultaneous operation of 1 million devices',
    },
    block04: {
      title: 'Professional "Cloud" partner',
    },
  },
  concept: {
    block01: {
      title: 'Every step, we uphold the concept of sustainable development',
    },
    block02: {
      title: 'In the future',
      label01: 'Raw materials',
      label02: 'Dismantling recycling',
      label03: 'Production manufacturing',
      label04: 'Product usage',
      label05: 'Logistics transportation',
    },
    block03: {
      '01': {
        title: 'Environmentally-friendly and energy-saving materials',
        subtitle01: 'Our products use high-efficiency batteries to reduce energy consumption.',
        subtitle02: 'In the future, renewable energy sources such as solar energy will also be used for charging.',
        // subtitle02: '未来也将使用太阳能等可再生能源进行充电',
      },
      '02': {
        title: 'Small in size, easy to transport',
        subtitle01:
          'Simple product design greatly reduces packaging volume, packaging materials and packaging waste, making it easier to be recycled, processed, and decomposed after use. Of course, we also provide assembly tools to make the assembly process easy, while also contributingto environmental protection.',
        subtitle02: '',
        subtitle03: '',
        subtitle04: '',
      },
      '03': {
        title: 'Smart Lumbar Support Follows Your Lumbar Automatically',
        subtitle01:
          'Through big data and humanized product design, it can adapt to users of different heights and body types, providing more comfortable experience.',
        subtitle02: '',
      },
      '04': {
        title: "More than 10 years' lifespan",
        subtitle01:
          'We continuously test the durability and maintainability of our products to continuously extend their service life, thereby reducing the frequency of product replacement or disposal and reducing waste generation.',
        subtitle02: '',
        subtitle03: '*The data is only for reference, and the specific use varies from person to person',
      },
      '05': {
        title: 'Environmentally-friendly and energy-saving materials',
        subtitle01: 'After the chair is assembled, the packaging may be re-used as a pet shelter for fun.',
      },
    },
    block04: {
      title: 'It is a packaging box, and it can be also a happy paradise.',
      label01: "Let's conserve resources,",
      label02: 'protect the environment,',
      label03: 'and create a sustainable future together.',
    },
  },
  bknews: {
    slogan: 'AI, Leads a Healthy Modern Life',
    news: 'News Center',
    tab1: '全部',
    tab2: '新闻资讯',
    tab3: '公司活动',
    more: 'Learn More',
    news_title1: "Breaking News! Backrobo's Air Smart Office Chair C2 Wins 2023 CES Innovation Design Award.",
    new_subtitle1:
      'Airly Chair (Deluxe Version) is an intelligent ergonomic office chair. Compared with current ergonomic chairs, Airly Chair is equipped with intelligent somatosensory chip and airly lumbar support following system (ALSFS*), which can adapt to different sitting postures and provide dynamic waist support to reduce sedentary hazards. It boasts functions such as body pressure sensing...',
    news_title2: 'AIWORKS GLOBAL 惊艳亮相拉斯维加斯CES展览',
    new_subtitle2:
      '美国当地时间1月5日，2023年国际消费电子展（CES）在拉斯维加斯拉开帷幕，在被新冠疫情打乱两年节奏后，CES终于回归线下。自1967年创办以来，CES已走过56个年头。据悉，本届CES展迎来3143家参展企业，其中中国大陆的参展商从去年的155家增加到480家。Interbrand全球品牌百强中的85家企业，40家顶级零售商均已...',
    news_title3: '品牌应邀出席第五届中国睡眠产业峰会',
    new_subtitle3:
      '3月21日上海贝氪若宝健康科技有限公司（下称“贝氪科技”）成为“2022新浪健康养生睡眠健康总评榜”上榜企业，荣获“2022睡眠产业新锐企业”称号，旗下添眠品牌也受邀出席2023世界睡眠日·中国睡眠产业峰会。在本次第五届睡眠产业峰会现场，贝氪科技董事长韩道虎先生以“智慧睡眠 AI大数据闭环应用”为主题发表演讲，描述了添眠品牌在未来...',
    news_title4: '贝氪科技首次亮相第五届进口博览会',
    new_subtitle4:
      '2022年11月5日，【上海：第五届中国国际进口博览会】在上海盛大开幕，贝氪若宝健康科技有限公司（以下简称“贝氪科技”）在“四叶草”中央广场-6天+365天一站式交易服务平台亮相本届盛会，全方位展示旗下两款代表产品——添眠智能止鼾枕&贝氪智能气动好腰椅。中国国际进口博览会是世界上第一个以进口为主题的国家级展会，共有145个国家、地区和国际组织参...',
    news_title5: '贝氪科技董事长韩道虎对话未来医疗服务平台动脉网',
    new_subtitle5:
      '医疗市场正随可支配收入的增长，以及健康管理意识的提高由疾病驱动向健康驱动转型。转型之下，消费医疗作为融合消费与医疗两大属性的板块，体现出极佳的成长性与发展稳定性。由于其终端覆盖人群广、应用消费场景丰富，未来可围绕消费者健康需求持续提供多元化产品与服务等属性，在当下以健康为中心的解决方案探索中，存在诸多可能性。对于企业而言，这无疑是一个极好的...',
    news_title5: '出席第五届中国睡眠产业峰会',
    new_subtitle5: '3月21日上海贝氪若宝健康科技有限公司（下称“贝氪科技”）成为“2022新浪健康养生睡眠健康总评榜”上榜企业，荣获“2022睡眠产业新锐企业”称号，旗下...',
    news_title6: '添眠AI深睡房已经入驻多家酒店品牌，为您开启智慧深睡新体验！',
    new_subtitle6:
      '添眠AI深睡房是面向未来酒旅宾客提供科学健康深睡的一种新的高端房型，房间内搭载数智化软硬件系统和智能设备，对用户进行实时的睡眠监测和睡眠诱导。目前多家酒店陆续上线了<添眠AI深睡房>的高端房型...',
  },
  us: {
    slogan1: 'We got your back',
    slogan2: 'Less Snoring, Better life ',
    about_us: 'About Us',
    us_info1:
      'Founded in 2019, Aiworks global is a global enterprise dedicated to applying artificial intelligence (AI) technology to the field of health, focusing on 8-hour healthy office and 8-hour smart sleep health care, based on AI health cloud and various hard-core products to improve "sit, sleep, walk" in order to cover about 20 hours of personal behavioral health intervention ecology every day.',
    us_info2:
      "Aiworks global owns two major brands: Nitetronic and Backrobo. Nitetronic focuses on the field of smart sleep, and Backrobo focuses on the field of smart office. While helping users pursue a high-quality life, health is integrated into everyone's daily life.",
    us_know_more: 'Learn More',
    history: 'Development History',
    history_2019: {
      info1: '上海贝氪若宝健康科技有限公司成立',
      info2: '苏州贝氪智能科技有限公司成立',
    },
    history_2020: {
      info1: '通过科技型中小型企业认定',
      info2: '获得中标研质量管理体系认证',
      info3: '孵化“Backrobo贝氪”智慧办公品牌',
      info4: '推出贝氪智能托腰办公椅并在小米有品首发众筹',
    },
    history_2021: {
      info1: '收购德国“Nitetronic”止鼾枕品牌',
      info2: '孵化”Nitetronic添眠”智慧睡眠品牌',
      info3: '推出Nitetronic添眠智能止鼾枕系列',
    },
    history_2022: {
      info1: '通过上海高新技术企业认定',
      info2: '以Aiworks Global海外企业命名正式开展全球业务',
      info3: 'We launched Nitetronic Z6 on Kickstarter & Indiegogo',
      info4: 'Aiworks Global participated in the 2022 China International Import Expo',
    },
    history_2023: {
      info1: 'Aiworks Global participated in the 2023 CES ',
      info2: '贝氪智能托腰办公椅华为商城首发众筹',
      info3: '推出医疗型添眠®M6型智能止鼾枕',
      info4: 'Backrobo Napping Pillow',
      info5: 'Nitetronic Sleep Eye Mask',
    },
    history_future: {
      info1: '上海贝氪若宝健康科技有限公司成立',
      info2: '苏州贝氪智能科技有限公司成立',
    },
    culture: 'Culture',
    culture_title1: 'Mission ',
    culture_subtitle1_l: 'Do healthy products to make people feel happy and healthy',
    culture_subtitle1_r: '',
    culture_title2: 'Vision ',
    culture_subtitle2_l: 'To be a global enterprise leading intelligent health technology',
    culture_subtitle2_r: '',
    culture_title3: 'Value',
    culture_subtitle3_1: 'Do something valuable and meaningful to the society',
    culture_subtitle3_2: 'Customer is the King',
    culture_subtitle3_3: 'Achieve success for the company and for yourself',
    culture_subtitle3_4: 'Work diligently and strive constantly',
    culture_subtitle3_5: 'Work happily and live blissfully',
    honor: 'Honor',
    honor_title1_t: '添眠品牌荣获',
    honor_title1_b: '天猫年度超级新物种奖',
    honor_title2_t: '获新浪健康养生',
    honor_title2_b: '睡眠产业新锐企业奖',
    honor_title3_t: '',
    honor_title3_b: '中国睡眠研究会成员',
  },
  help: {
    help: 'Service and Support',
    help_title: 'Welcome to Aiworks service center',
    help_subtitle: '',
    nitetronic: 'Nitetronic',
    backrobo: 'Backrobo',
    help_install: '安装视频',
    help_intro: '产品说明书',
    help_app: 'APP下载',
    help_problem: 'FAQs',
    help_contact: 'Contact Us',
    help_contact_call: 'Phone',
    help_contact_mail: 'Email',
    help_contact_wechat: '官方微信',
    nitetronic_problem1_question: '添眠智能止鼾枕 Z1和Z1P有什么区别？',
    nitetronic_problem1_answer:
      '两者外观一致，Z1是固定枕高版本，Z1P是高低可调版本，枕头内置2层可拆卸垫层，通过拉开拉链，拿掉枕头内的垫层，来降低枕头的整体高度。',
    nitetronic_problem2_question: 'What is the difference between Nitetronic Smart Anti-Snore Pillow Z1 and Z6?',
    nitetronic_problem2_answer:
      "We add two buttons on the control-box of Z6. People can adjust the snoring detection sensitivity and intervention  intensity by pressing the buttons. Old people can easily use it. Redesign the style and upgrade the brand's tonality. Upgrading noise reduction technology to create more comfortable sleep. WIth AI algorithm, the effect of snoring detection and snoring intervention gets better.",
    nitetronic_problem3_question: 'How to configure the network?',
    nitetronic_problem3_answer_01:
      '1.Download Nitetronic APP by scanning the QR code on the control-box, you can download the app from APP Store or Google Play.',
    nitetronic_problem3_answer_02: '2.Power on. Long press wifi button on the control box till the wifi light flashes.',
    nitetronic_problem3_answer_03:
      '3.click the top right button No device added at Report to add the device. You can scan the UDI code at the back of the control-box to connect the Pillow; or you can add the pillow manually.',
    nitetronic_problem3_answer_04: '4.Follow the instructions in the app to operate.',
    nitetronic_problem4_question: 'How to wash the Nitetronic Smart Anti-Snore Pillow?',
    nitetronic_problem4_answer:
      'The main body of the Nitetronic Smart Anti-Snore Pillow can not be washed or dry-cleaned. After removing the pillowcase, place the pillow in a well-ventilated area or gently wipe the protective inner cover surface with a damp cloth to remove dust or stains. The inner cover is designed to protect the sensitive technology components inside the pillow and should not be removed. Any damage to the protective inner cover is not covered under warranty. The pillowcase can be removed and cleaned according to the washing instructions on the label inside the pillowcase. Please turn it inside out and zip it up before washing.',
    nitetronic_problem5_question: 'How does the smart anti-snore pillow work?',
    nitetronic_problem5_answer:
      "Nitetronic Smart Anti-Snore Pillow uses the user's voice frequency and sound vibration to recognize snoring through a multi-factor data pattern, unaffected by other sounds (such as partner's snoring, music, chatting). When the user snores during sleep, the sensor detects the snoring and inflates the corresponding position's airbag in the pillow, gently pushing the head to the side, widening the airway, and reduce snoring without disturbing sleep.",
    nitetronic_problem6_question: 'How to check if the pillow is normal without snoring?',
    nitetronic_problem6_answer:
      'You can use the demo mode to check if the pillow is working. The Method: Click "Me" in the app, then click on the "Demo Mode" option. Click on "Start Demo" to activate the demo mode. Keep your head on the pillow to experience the demo. In demo mode, the airbags will simulate snoring intervention actions and perform airbag rotation.',
    backrobo_problem1_question: 'C1、C1X、C2有什么区别',
    backrobo_problem1_answer_01: 'C1X和C1都是使用米家app，区别是C1X新增背部承托区、可调节扶手、可拆卸电池、W型人体工学坐垫，可选颜色也更加丰富。',
    backrobo_problem1_answer_02: 'C2使用贝氪自研app，在C1X基础上新增腰部热敷及午休模式，并且可在app内随时开启按摩，产品材质方面也有升级。',
    backrobo_problem2_question: 'What adjustment functions does the smart office chair have?',
    backrobo_problem2_answer:
      'The seat height of the office chair can be adjusted up and down by 70mm. The backrest angle can recline up to 30 degrees. The headrest can be adjusted up and down by 40mm and can rotate up to 40 degrees. The intensity of the reclining can be adjusted. The manual and automatic modes can be switched through the buttons on the seat. The armrests are adjustable: they can be raised and lowered by 60mm, rotated left and right by 12 degrees, and slid forward and backward by 60mm. Users can also adjust the temperature of the lumbar  heating  and set the sitting duration through the Backrobo APP. More adjustments in Backrobo APP.',
    backrobo_problem3_question: 'How to use Backrobo Air Smart Chair?',
    backrobo_problem3_answer:
      'Firstly, please install the chair. Then sit on the chair and the chair will be automatically activated.Just enjoy it. If it is not be activated, please charge it immediately.When the battery is sufficient, please ait on the seat to activate the chair.',
    backrobo_problem4_question: 'How to configure the network?',
    backrobo_problem4_answer_01: '1.Sit on the seat to activate the chair.',
    backrobo_problem4_answer_02: '2.Scan the QR code in the User Manual to download Backrobo APP',
    backrobo_problem4_answer_03: '3.Open the backrobo app, register an account and log in the app.',
    backrobo_problem4_answer_04:
      '4.Enter your height and weight, and the Backrobo smart lumbar support will customize the optimal lumbar support strength for you.Click on the "Home" page in the app, then click "Add device" and scan the QR code under the seat cushion or manually add it.',
    backrobo_problem4_answer_05: '5.Please follow the instructions on the app page to complete the device addition, and then you can enjoy it.',

    backrobo_problem5_question: 'Would you please introduce the Time Reminder Mode?',
    backrobo_problem5_answer:
      'Users can set sedentary time through the APP. When the cumulative sitting time reaches the time we set, the airbag on the back of the office chair will automatically inflate and deflate to massage the lumbar.And the user will be reminded to leave the seat.The massage action lasts 2 minutes.',
    backrobo_problem6_question: 'How do you clean the Backrobo chair?',
    backrobo_problem6_answer: 'Spray a small amount of mild detergent onto a dry cloth, wipe the stained area, and allow or blow dry, not washable.',
  },
}
